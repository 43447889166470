<div class="screentab" >
    <div class="row homepage project-back">
        <mat-icon mat-icon-button (click)="navigateBack()"
            class="mat-icon-home" >west</mat-icon>
        <span  class="project-name" > {{data?.library_name}}</span>
    </div>
    <div class="tab-container1">
        <mat-button-toggle-group class="tab-container" aria-label="Favorite Color">
            <mat-button-toggle value="tab1" class="tab"
            #toggleButton1
             (click)="changeSelectedTab(1,toggleButton1)" 
             >NGS files</mat-button-toggle>
            <mat-button-toggle value="tab2" 
                class="tab" 
                #toggleButton2
            (click)="changeSelectedTab(2,toggleButton2)" 
             >Chemical Structure table files
            </mat-button-toggle>
            <mat-button-toggle value="tab3"
              class="tab"
            #toggleButton3
            (click)="changeSelectedTab(3,toggleButton3)"
                >Library definition files</mat-button-toggle>
            
        </mat-button-toggle-group>
        <div class="mat-btn-next"
        [ngClass]="{'tab-two': selectedTab == 'tab2'}">

        </div><br>

        <div *ngIf="selectedTab === 'tab1'">
            <div class="row add-scientist" *ngIf="data.ngs_files">
                <app-bar-chart [context]="'library'"></app-bar-chart>
                <div class="mat-elevation-z8">
                    <br>
                   </div>
            </div>
            <div class="row add-scientist" style="margin: 3em;" *ngIf="!data.ngs_files">
                <p>NO NAIVE SCREENING AVAILABLE</p>
                </div>
        </div>

        <div *ngIf="selectedTab == 'tab2'" >
            <div *ngIf="!loading2" class="row add-scientist" style="margin: 3em; display: flex;overflow-x: auto;flex-direction: column;">
                <div class="row add-scientist">
                    <button mat-raised-button color="primary" style="float:right;margin: 2em 0"             
                    (click)="export1()"><span class="btn-name">Export</span>
                    
                </button> 
                </div>
                
                <app-table-component [columns]="columnStruct"  [tableData]="structData" listName="hitlistDetail1"  
                [showSearch]="false"    *ngIf="!zip"  />

                <p *ngIf="zip">Can not display content of very big files</p>
      
            </div>
            <div *ngIf="loading2" class="loading-container">
                <app-loading-spinner></app-loading-spinner>
              </div>
            
        </div>

        <div *ngIf="selectedTab === 'tab3'" >
            <div *ngIf="!loading3" class="row add-scientist" style="margin: 3em; display: flex;overflow-x: auto;flex-direction: column;">
                <div class="row add-scientist">
                    <button mat-raised-button color="primary" style="float:right;margin:2em 0"             
                    (click)="export2()"><span class="btn-name">Export</span>
                    
                </button> 
                </div>
                <app-table-component [columns]="columnLdf"  [tableData]="ldfData" listName="hitlistDetail2"  
                [showSearch]="false"      />
      
            </div>
            <div *ngIf="loading3" class="loading-container">
                <app-loading-spinner></app-loading-spinner>
              </div>
            
        </div>


    </div>
</div>