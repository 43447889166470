import { Component, Renderer2, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import Chart from 'chart.js/auto';
import * as JSZip from 'jszip';

import { saveAs } from 'file-saver';
import { DataService } from 'src/app/data.service';



@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})



export class BarChartComponent implements OnInit {
  @Input() context = 'screen'
  chartView: boolean = true;
  tableView: boolean = false
  // xData = ['Total', 'Max 1 N', 'Filter QC', 'Demuxed', 'Allowed', 'Paired', 'Distinct', 'Filter Len', 'Threshold'];
  myChart!: any;
  // datasetData = [
  //   [4731620, 4631220, 4631620, 4827928, 2827238, 2731620, 1131120, 1732320, 165234],
  //   [4731620, 4631220, 4631620, 4827928, 2827238, 2731620, 2867620, 2731620, 1531520]
  // ];
  xData: any = []
  datasetData: any = []
  config: any;
  chartVersion: any;
  lastLegendIndexClicked = null;
  chartViewData: any;
  chartViewDataMapped: any;
  chartViewDataMappedSorted: any = [];
  conditionName: string = '';
  screenName: string = '';
  totalMolucules: any;
  screenResultColunms: Array<Object> = [
    { key: 'merged_library_id', displayName: 'Library ID' },
    { key: 'smile_all', displayName: 'Smiles' },
    // { key: 'enrichment_factor', displayName: 'Enrichment Factor' },
    { key: 'ef1 relative abundance normalized by naive copy number of given variant', displayName: 'ef1' },
    { key: 'ef2 relative abundance normalized by average naive copy number of variants', displayName: 'ef2' },
    { key: 'ef3 variant copy number in experiment normalized by variant copy number naive', displayName: 'ef3' },
    { key: 'ef4 variant copy number in experiment normalized by average naive copy number of variants', displayName: 'ef4' },
    { key: 'ef5 variant copy number times theoretical library size normalized by total number of all assigned variants', displayName: 'ef5' },
    { key: 'ef6 normalized z score', displayName: 'ef6' },
    { key: 'copy_number', displayName: 'Copy Number' },
    { key: 'statusIcon', displayName: '  ' },
  ];
  screenResult:any;
  total_row_count:any;
  


  screenResult1 = [
    {
      id: 'Library A',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library B',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library C',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library D',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library F',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library G',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library X',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library Y',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library Z',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library A',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library A',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library A',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
    {
      id: 'Library A',
      smiles: '([C@H](NC(OCC1c2c(c3c1cccc3)cccc2)=O)(C(=O)O)CCNC(=O)OCC=C',
      factor: '0.5',
      copyNumber: 10,
      statusIcon: 'DONE'
    },
  ]

  constructor(private renderer: Renderer2,
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    let chartDataStorage: any = localStorage.getItem('chartData');
    const chartData = JSON.parse(chartDataStorage);
    this.chartViewData = { ...chartData };
    this.chartViewDataMapped = {};
    this.conditionName = this.chartViewData.Condition
    this.screenName = this.chartViewData.screen_name;
    this.totalMolucules = this.chartViewData.total_molecules;
    delete this.chartViewData.Condition;
    //delete this.chartViewData.total_molecules;
    delete this.chartViewData.screen_name;
    let values = [];
    // Convert the object to an array of key-value pairs
    const entries = Object.entries(this.chartViewData);
    // Sort the array in descending order by values
    entries.sort((a:any, b:any) => parseInt(b[1], 10) - parseInt(a[1], 10));
    // Convert the sorted array back to an object if needed
    const sortedObject = Object.fromEntries(entries);
    for (const [key, value] of Object.entries(sortedObject)) {
      const keyMapped = key == 'total_molecules'? 'Identified Molecules' : key.replace(/_/g, ' ');
      this.chartViewDataMapped[keyMapped] = value
      this.chartViewDataMappedSorted.push({[keyMapped]:value});
      this.xData.push(keyMapped);
      values.push(value)
    }
    values.sort((a:any, b:any) => parseInt(b, 10) - parseInt(a, 10));
    this.datasetData.push(values);
    this.renderChart();
    this.showChart(true)
  }

  showChart(shouldShowCanvas: boolean) {
    if (shouldShowCanvas) {
      this.tableView = false;
      this.chartView = true;
    } else {
      this.tableView = true;
      this.chartView = false;
    }
    const data:any = localStorage.getItem('hitmiles_data');
    const count:any = localStorage.getItem('hitmiles_data_total_count');
    this.total_row_count = JSON.parse(count);
    this.screenResult = JSON.parse(data);
  }

  navigateToScreenDetails() {
    this.router.navigate(['screens/screen-details', 1]);
  }

  navigateToScreenList() {
    this.router.navigate(['screens']);
  }

  getKey(item: any): string {
    return Object.keys(item)[0];
  }
  
  getValue(item: any): any {
    return Object.values(item)[0];
  }

  generateCSVData(exportType:string):string {
    const data1 = []
    if(exportType === 'moreDetail'){
      for(let i = 0; i < this.chartViewDataMappedSorted.length; i++){
        data1.push([`${Object.keys(this.chartViewDataMappedSorted[i])[0]}`, `${Object.values(this.chartViewDataMappedSorted[i])[0]}`])
      }
    }else if(exportType === 'table'){
      for(let i = 0; i < this.screenResult.length; i++){
        let str = ""; 
        //data1.push([`${Object.keys(this.screenResult[i])[0]}`, `${Object.values(this.screenResult[i])[0]}`]);
        for (const [key, value] of Object.entries(this.screenResult[i])) {
          if(str !== ""){
            str = str+','+`${key} : ${value}`;
          }else{
            str = `${key} : ${value}`;
          }
          // data1.push([key, value]);
        }
        data1.push([str]);
      } 
    }
    const csvContent = data1.map(row => row.join(',')).join('\n');
    return 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
  }

  chartExport(exportType:string){
    if(exportType === 'moreDetail'){
      const csvData = this.generateCSVData(exportType);
      saveAs(csvData, 'more_details_data.csv');
    }else if(exportType === 'table'){
      // const csvData = this.generateCSVData(exportType);
      // saveAs(csvData, 'table_result_data.csv');
      // console.log("Screen details storage............", this.screenDetails);
      const data:any = localStorage.getItem('hitmiles_CSV_PresignedURL');
      const obj:any = JSON.parse(data);
      console.log('jjjjjjjjjjjj  ', obj)
      //this.downloadFilesFromS3(obj);
      this.downloadAllFilesAsZip(obj)
    }
  }

  downloadFile(preSignedUrl:string) {
    this.dataService.downloadFileFromS3(preSignedUrl).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);

      // Create an invisible anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'table_result_data.csv'; // Set the file name
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  downloadAllFilesAsZip(preSignedUrls: string[]) {
    if (preSignedUrls.length === 0) {
      alert('No files to download.');
      return;
    }

    const zip = new JSZip();
    let completedDownloads = 0;

    preSignedUrls.forEach((preSignedUrl, index) => {
      this.dataService.downloadFileFromS3(preSignedUrl).subscribe((data: Blob) => {
        const fileName = `File_${index + 1}.csv`; // Assign unique names
        zip.file(fileName, data); // Add file to zip

        completedDownloads++;

        // If all files are downloaded, create and save the zip
        if (completedDownloads === preSignedUrls.length) {
          zip.generateAsync({ type: 'blob' }).then((zipContent: string | Blob) => {
            saveAs(zipContent, this.screenName+ `_`+this.conditionName+`.zip`); // Set ZIP file name
          });
        }
      }, (error) => {
        console.error(`Error downloading file:`, error);
      });
    });
  }


  downloadFilesFromS3(preSignedUrls: string[]): void {

    const concurrencyLimit = 10; // Limit concurrent downloads to 10
    let activeDownloads = 0;
    let currentIndex = 0;

    const downloadNext = () => {
    if (currentIndex >= preSignedUrls.length) return;

    // Start a new download
    activeDownloads++;
    const url = preSignedUrls[currentIndex];
    currentIndex++;
      this.dataService.downloadFileFromS3(url).subscribe((data: Blob) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const downloadUrl = window.URL.createObjectURL(blob);
  
        // Create an invisible anchor element and trigger the download
        const a = document.createElement('a');
        a.href = downloadUrl;
  
        // Simulate a folder by prepending "folder_name/" to each file name
        a.download = this.screenName+ `_`+this.conditionName+`/_${currentIndex + 1}.csv`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
  
        // Clean up the object URL to release memory
        window.URL.revokeObjectURL(downloadUrl);

        activeDownloads--;
        downloadNext();
      }, (error) => {
        console.error(`Error downloading file at URL ${url}:`, error);
        // Handle error and continue
        activeDownloads--;
        downloadNext();
      });
    }

    // Start initial downloads
  for (let i = 0; i < concurrencyLimit; i++) {
    downloadNext();
  }
    
    
  }

  paginationData(page_number: number){
    alert(page_number);
  }

  

  renderChart() {
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    const data = {
      labels: this.xData,
      datasets: [
        ...this.datasetData.map((data: any, index: any) => ({
          label: this.xData[index],
          backgroundColor: index == 0 ? '#2196F3' : '#E9ECEF',
          barThickness: 50,
          data: data,
        })),
      ]

    };
    // config 
    this.config = {
      type: 'bar',
      data,
      options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false, // Remove vertical grid lines
            },
          },
          y: {
            beginAtZero: true, // Set a maximum value to control the x-axis range
            grid: {
              display: false, // Remove vertical grid lines
            },
          }
        },
        plugins: {
          legend: {
            position: 'right', // Move legend to the right side
            align: 'start', // Align the legend to the start (top of the chart)

            labels: {
              generateLabels: (chart: any) => chart.data.labels.map((label: any, i: any) => ({
                datasetIndex: i,
                text: label,
                fillStyle: '#2196F3',
                strokeStyle: '#2196F3',
              })),
              boxWidth: 12, // Increase filter size
              fontSize: 14, // Increase filter font size
            },

            onClick: (event: any, legendItem: any, legend: any) => {
              const index = legendItem.datasetIndex;
              this.xData.forEach((data: any, i: any) => {
                if (this.myChart?.getDataVisibility(i) == false) {
                  this.myChart?.toggleDataVisibility(i);
                }
              })
              this.myChart?.update();
              this.xData.forEach((data: any, i: any) => {
                if (index !== i && this.myChart?.getDataVisibility(i) == true) {
                  this.myChart?.toggleDataVisibility(i);
                }
                this.myChart?.update();
              })

            },
          },
        },
        responsive: true,
      }
    };

    this.myChart = new Chart(
      ctx,
      this.config
    );


    // Instantly assign Chart.js version
    // this.chartVersion = document.getElementById('chartVersion');
    // this.chartVersion.innerText = Chart.version;
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];
