import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ModalServiceService } from 'src/app/modal-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationModal } from 'src/app/components/confirmation-modal/confirmation-modal';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';



//TODO: MAKE INPUTS FOR:
// 1- data
// 2- columns css
// 3- has selection
// 4-  action buttons


interface TableColumn {
  key: string;
  displayName: string;
}

@Component({
  selector: 'app-table-component',
  templateUrl: './table-component.component.html',
  styleUrls: ['./table-component.component.css']
})
export class TableComponentComponent implements OnInit {

  @Input() tableData?: any = []
  @Input() columns?: any;
  @Input() showCheckbox: boolean = false;
  @Input() clickable: boolean = false;


  selection = new SelectionModel<any>(true, []);

  //TODO: check if this list is used
  showTableList = true;
  @Input() showAddRow = false;
  @Input() showEditRow = false;
  @Input() showDuplicateOption = false;
  @Input() showActionMenu = false;
  @Input() listName: string = '';
  @Input() showSearch = true;
  @Input() removeDelete = true;
  @Input() export = false;

  @Input() filters: any[] = []

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild('sidenav') sidenav!: MatSidenav;


  @Output() showRowDetails = new EventEmitter<void>();
  @Output() editRow = new EventEmitter<void>();
  @Output() duplicateRow = new EventEmitter<void>();
  @Output() reanalyzeRow = new EventEmitter<void>();
  @Output() applyFilter = new EventEmitter<any>();
  @Output() showDescription = new EventEmitter<any>();
  @Output() showScreen = new EventEmitter<any>();
  @Output() deleteRow = new EventEmitter<any>();
  @Output() checkedRow = new EventEmitter<any>();
  @Output() filterItem = new EventEmitter<any>();
  @Output() filterKey = new EventEmitter<any>()
  currentRow: any;

  name: string = '';
  project: string = '';
  email: string = '';
  role!: string;

  removeDuplicate: any = true;
  //removeDelete: any = true;

  pageSize: number = 25;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  currentPage: number = 0;

  // Displayed items for the current page
  displayedItems: any = [];
  totalItems: number = 0;

  dataSource = new MatTableDataSource(this.tableData);
  filterValue: any;
  filtered: boolean = false;
  filteredData: any = [];
  sorted: boolean = false;
  sortedData: any = [];

  noSortingColumns: string[] = ['merged_library_id', 'smile_all', 'enrichment_factor', 'copy_number'];

  filterArray: any[] = []

  refinedFilterArray: any[] = [
    {
        "key": "project_lead",
        "value": []
    },
    {
        "key": "main_target_protein",
        "value": []
    },
    {
        "key": "project_status",
        "value": []
    },
    {
        "key": "project_name",
        "value": []
    },
    {
        "key": "unique_project_id",
        "value": []
    },
    {
      "key": "scientist_name",
      "value": []
    },
    {
      "key": "ldf_files",
      "value": []
    },
    {
      "key": "experiment_number",
      "value": []
    },
    {
      "key": "submission",
      "value": []
    },
    {
      "key": "analyzedby",
      "value": []
    }
  
  ]

  showFilter = false;
  searchText: string = '';

  checkboxes: { [key: string]: { [val: string]: boolean } } = {}

  panelOpenState = false
  fromDate = ''
  toDate = ''
  reset = false

  constructor(private dialog: MatDialog,
    private modalService: ModalServiceService, private snackBar: MatSnackBar) {
    // Create 100 users
    // Assign the data to the data source for the table to render
    
  }

  //TODO: check name of functions
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData?.length;
    return numSelected === numRows;
  }

  showSelectedRows() {
    const selectedRows = this.getSelectedRows();
    const idArray: any[] = []
    selectedRows.forEach(item => {
      idArray.push({id: item.hitlist_id, name: item.hit_list_name})
    })
    
    this.checkedRow.emit(idArray)
  }

  getSelectedRows() {
    return this.selection.selected;
  }
  
  ngOnInit() {

    this.dataSource.sort = this.sort;
    if (this.columns.length === 5 || this.columns.length === 4) this.removeDuplicate = false;
    if (this.columns.length === 4) this.removeDelete = false;

    this.updateDisplayedItems();
    // this.dataSource.paginator = this.paginator; 


  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableData']) {
      this.totalItems = this.tableData?.length || 0
      this.tableData = changes['tableData']?.currentValue
      // this.dataSource = new MatTableDataSource(this.tableData);
      this.updateDisplayedItems();
    }
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.displayedItems?.forEach((row: any) => this.selection.select(row));
  }

  

  onShowDetails(row: any, edit?: boolean) {
    this.showRowDetails.emit(row)
  }

  onEditRow(row: any, rowIndex: number) {
    this.editRow.emit(row);
  }

  onDuplicateRow(row: any, index: number) {
    this.duplicateRow.emit(row);
  }


  onReanalyze(row: any, index: number) {
    this.reanalyzeRow.emit(row);
  }

  //TODO: Maybe add it to different user
  openDialog(row: any) {

    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        message: 'Are you sure want to delete this ' + this.listName + '?',
        deleteRow: this.deleteRow,
        rowData: row,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
  }

  getColumnKeys(addMenu: boolean): string[] {
    let result = this.columns.map((column: any) => column.key);

    if (addMenu) {
      result.push('menu')
    }
    return result;
  }


  // Check if the row should display the checkbox
  isCheckboxRow(row: any): boolean {
    // Replace this condition with your logic to determine when to show the checkbox
    // For example, let's show the checkbox if the row has a 'selected' property set to true
    return this.showCheckbox && row.selected === true;
  }



  isMenuRow(index: number, row: any): boolean {
    // Replace this condition with your logic to determine when to show the menu button
    // For example, let's show the menu button for every second row
    return (index + 1) % 2 === 0;
  }


  onApplyFilter(event: Event) {
    this.applyFilter.emit(event);
  }

  filterUsers(event?: Event) {
    this.filterValue = event && (event.target as HTMLInputElement).value;
    if (!this.filterValue) this.filtered = false;
    if (this.filterValue) {
      this.filterValue = this.filterValue.trim().toLowerCase();
      this.filtered = true;
    }

    if (this.dataSource?.paginator) {
      this.dataSource.paginator.firstPage();
    }
    if (this.dataSource && this.tableData) {
      this.dataSource.data = this.tableData.filter((item: any) => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && item[key].toString().toLowerCase().includes(this.filterValue)) {
            return true;
          }
        }
        return false;
      });
    }

    this.filteredData = this.dataSource?.data;
    this.totalItems = this.filteredData.length;
    this.updateDisplayedItems()
  }


  toggleSlideBar() {
    this.sidenav.toggle();
  }

  onShowDescription(rowDetails: any) {
    this.showDescription.emit(rowDetails);
  }

  onShowScreen(screen_name: string) {
    this.showScreen.emit(screen_name)
  }


  testRowClick(rowIndex: number) {
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize
    this.currentPage = event.pageIndex;
    this.updateDisplayedItems();
  }



  updateDisplayedItems(): void {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.dataSource.sort = this.sort;
    if (this.filtered) {
      this.displayedItems = [...this.filteredData]
      this.displayedItems = this.displayedItems?.slice(startIndex, endIndex) || [];
    }
    else if (this.sorted) {
      this.displayedItems = [...this.sortedData]
      this.displayedItems = this.displayedItems?.slice(startIndex, endIndex) || [];
    }
    else {
      this.displayedItems = this.tableData?.slice(startIndex, endIndex) || [];
    }
    this.dataSource = new MatTableDataSource(this.displayedItems);
  }
  customSort(data: any): any {
    const active = this.sort?.active;
    const direction = this.sort?.direction;

    if (!active || direction === '') {
      return data;
    }

    return data.sort((a: any, b: any) => {
      const valueA = (a[active] || '').toLowerCase();
      const valueB = (b[active] || '').toLowerCase();
      const compare = valueA.localeCompare(valueB);

      return direction === 'asc' ? compare : -compare;
    });
  }

  sortAllData(sort: any) {
    const currentSortDirection = this.sort.direction;
    if (!currentSortDirection) {
      this.sorted = false;
    } else {
      this.sorted = true;
    }
    this.sorted = true;
    this.dataSource.sortData = (data, sort) => {
      this.sortedData = this.customSort(this.tableData);
      return this.sortedData;
    }
    if (this.dataSource && this.dataSource.data) {
      this.displayedItems = this.sortedData
      this.updateDisplayedItems()
    }
  }

  showFilterUI() {
    this.showFilter = !this.showFilter
  }

  onCheck(key: string, val: string, event: any) {
    this.reset = false
    if (!this.checkboxes[key]) {
      this.checkboxes[key] = {};
    }
    this.checkboxes[key][val] = event.checked
    const index = this.refinedFilterArray.findIndex(item => item.key == key)
    if (event.checked) {
      this.refinedFilterArray[index].value.push(val)
    } else {
      this.updateFilterArray(index, val)
    }

    //this.filterItem.emit(this.filterArray)
    this.filterItem.emit(this.refinedFilterArray) 
   
  }

  confirm() {
    //this.filterItem.emit(this.filterArray)
  }

 

  updateFilterArray(index: number, value: string) {
    const ind = this.refinedFilterArray[index].value.findIndex((item: string) => item == value)
    if(ind !== -1) {
      this.refinedFilterArray[index].value.splice(ind, 1)
    }
  }



  searchFilter(event: any) {
    this.searchText = event.target.value
  }

  isChecked(key: string, val: string): boolean {
    return this.checkboxes[key] && this.checkboxes[key][val];
  }

  isNotChecked(key: string, val: string): boolean {
    this.checkboxes = {}
    return false;
  }

  setFrom(event: any, key: string) {
    this.reset = false
    this.fromDate = event.target.value
    const index = this.refinedFilterArray.findIndex(item => item.key == key)
    this.refinedFilterArray[index].value[0] = this.fromDate
    this.filterItem.emit(this.refinedFilterArray) 
  }

  setTo(event: any, key: string) {
    this.reset = false
    this.toDate = event.target.value
    const index = this.refinedFilterArray.findIndex(item => item.key == key)
    this.refinedFilterArray[index].value[1] = this.toDate
    this.filterItem.emit(this.refinedFilterArray) 
  }

  resetFilter() {
    this.reset = true
    this.refinedFilterArray = [
      {
          "key": "project_lead",
          "value": []
      },
      {
          "key": "main_target_protein",
          "value": []
      },
      {
          "key": "project_status",
          "value": []
      },
      {
          "key": "project_name",
          "value": []
      },
      {
          "key": "unique_project_id",
          "value": []
      },
      {
        "key": "scientist_name",
        "value": []
      },
      {
        "key": "ldf_files",
        "value": []
      },
      {
        "key": "experiment_number",
        "value": []
      },
      {
        "key": "submission",
        "value": []
      },
      {
        "key": "analyzedby",
        "value": []
      }
    
    ]
  
    this.fromDate = ''
    this.toDate = ''
    this.filterItem.emit(this.refinedFilterArray)
  }

}

