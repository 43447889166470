import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ModalServiceService } from 'src/app/modal-service.service';
import { firstValueFrom } from 'rxjs';
import * as XLSX from 'xlsx';

import { MatButtonToggle } from '@angular/material/button-toggle';


export interface ScreenResultData {
  name: string;
  protein: string;
  molecules: string;
  libraries: string;
  experimentalId: string;
  description: string;
  statusIcon: string;
}


@Component({
  selector: 'app-library-details',
  templateUrl: './library-details.component.html',
  styleUrls: ['./library-details.component.css']
})
export class LibraryDetailsComponent {

  showScreenList: boolean = true;
  showBarchart: boolean = false;
  screenResult: any;
  clickable: boolean = true;
  screenName!: string;
  libraryDetails: any;
  statisticsList: any;
  screenResultList: any;
  chartData: any;
  loading: boolean = false;
  loading3: boolean = false;
  loading2: boolean = false

  selectedTab = 'tab1'; // Initial selected tab
  @ViewChild('toggleButton1') toggleButton1!: MatButtonToggle;
  @ViewChild('toggleButton2') toggleButton2!: MatButtonToggle;
  @ViewChild('toggleButton3') toggleButton3!: MatButtonToggle;

  tab1Selected = true;
  tab2Selected = false;
  tab3Selected = false;
  data: any
  structData = []
  columnStruct: Array<Object> = [];
  ldfData = []
  columnLdf: Array<Object> = [];
  zip = false

  constructor(private dialog: MatDialog,
    private modalService: ModalServiceService,
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
      const navigation: any = this.router.getCurrentNavigation();
      this.data = navigation.extras.state.data
      console.log('print----------------- ', this.data)

      const ldfPath = this.data.ldf_files + '.xlsx';
      const structPath = 'STRUCT_' + this.data.ldf_files + '.csv'
      this.zip = this.data.struct_file.endsWith(".gz") ? true : false
      const ldfPayload = {
        "operation_type": "data",
        "hitlist_id": ldfPath,
        "table_name": "bay-delt-hitlists",
        "context": 'library'
      }
      const structPayload = {
        "operation_type": "data",
        "hitlist_id": this.data.struct_file,
        "table_name": "bay-delt-hitlists",
        "context": 'library'
      }
  
      this.loading3 = true;
      this.loading2 = true;
      this.dataService.getHitlistDetails(ldfPayload).subscribe(res=> {
        this.ldfData = res.body
        const keysArray = Object.keys(res.body[0]);
        this.loading3 = false;

        // Transform keys into an array of objects with key and displayName
        this.columnLdf = keysArray.map(key => ({
            key: key,
            displayName: key
        }));
      })

      this.dataService.getHitlistDetails(structPayload).subscribe(res=> {
        this.structData = res.body
        this.loading2 = false
        // Get the keys of the response object
        const keysArray = Object.keys(res.body[0]);

        // Transform keys into an array of objects with key and displayName
        this.columnStruct = keysArray.map(key => ({
            key: key,
            displayName: key
        }));
      
      },
      (error)=> {
        this.loading2 = false
        this.zip = true
        console.log(error)
      });
      
      
            

  }

  ngOnInit() {
  }

  navigateBack() {
    this.router.navigate(['libraries'])

  }

  goBack() {

  }

  export1() {
    const structPath = 'STRUCT_' + this.data.ldf_files + '.csv'
    const structPayload = {
      "operation_type": "export",
      "hitlist_id": this.data.struct_file,
      "table_name": "bay-delt-hitlists",
      "context": 'library'
    }

    // this.dataService.getHitlistDetails(structPayload).subscribe(res=> {
    //   this.structData = res.body
    //   this.loading2 = false
    
    
    // })

    // const csvContent = this.convertToCsv(this.structData);
    // this.downloadFile(csvContent, 'STRUCT_' + this.data.ldf_files + '.csv');

    this.dataService.getHitlistDetails(structPayload).subscribe(res=> {
      console.log(res)
      this.loading2 = false
      this.downloadFile1(res.body)
      
    
    })


  }

  downloadFile1(url: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file'; // Optional: specify a filename
    anchor.target = '_blank'; // Opens in a new tab (optional)
    anchor.click();
  }
  export2() {
    const xlsxContent = this.convertToXlsx(this.ldfData);
    this.downloadFile2(xlsxContent, this.data.ldf_files + '.xlsx');
  }


  convertToXlsx(data: any): ArrayBuffer {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate XLSX file and return as ArrayBuffer
    return XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  }


  convertToCsv(data: any): string {
    if (!data || !data.length) {
      return '';
    }

    const keys = Object.keys(data[0]);
    const csvRows = [];

    // Create the header row
    csvRows.push(keys.join(','));

    // Create data rows
    for (const row of data) {
      const values = keys.map(key => {
        const escapedValue = ('' + row[key]).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  downloadFile(content: string, filename: string) {
    const blob = new Blob([content], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
  changeSelectedTab(tabNumber: number, button: MatButtonToggle) {
    this.selectedTab = `tab${tabNumber}`
  }

  downloadFile2(content: ArrayBuffer, filename: string) {
    const blob = new Blob([content], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }


}
